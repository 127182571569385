<template>
  <v-app class="app">
    <v-toolbar app>
      <v-toolbar-side-icon @click="showDrawer = !showDrawer"></v-toolbar-side-icon>
      <v-toolbar-title class="headline text-uppercase">
        <span>Chess Club</span>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <span class="font-weight-light">CLASSIC</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <span>
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link :to="{name: 'members'}">Members</router-link>
      </span>

      <v-spacer></v-spacer>

      <v-btn
        flat
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
      >
        <span class="mr-2">Made with Vuetify - Latest Release</span>
      </v-btn>
    </v-toolbar>
    <v-navigation-drawer 
      app dark
      width="200"
      class="blue lighten-2"
      v-model="showDrawer"
    >
      <v-list class="white--text">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              Chess Club Classic 
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-spacer></v-spacer>
        <v-list-tile router to="/">
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            Home
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile router to="/about">
          <v-list-tile-action>
            <v-icon>info</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            About
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile router to="/members">
          <v-list-tile-action>
            <v-icon>list</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            Members
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-content>
      <v-container fluid> 
        <router-view/>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
//import HomePage from './components/HomePage'

export default {
  name: 'App',
  data () {
    return {
      showDrawer: null
    }
  }
}
</script>

<style>
  a {
    font-weight: bold;
    font-size: 10pt;
    color: steelblue;
    text-decoration: none;
    margin-right: 1em;
    margin-left: 1em;
    padding: 5px;
    border-radius: 5px;
  }

  a:hover {
    background-color: lightblue;
  }

  .router-link-exact-active {
    color: green;
  }

  
</style>

